import { Mp3Duration } from './mp3_utils';

const getAudioFileStats = async (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const mp3Duration = new Mp3Duration();
    const fileDuration = mp3Duration.getDuration(e.target.result);
    resolve({ size: file.size, duration: Math.ceil(fileDuration) });
  };
  reader.readAsArrayBuffer(file);
});

export default { getAudioFileStats };
