<template>
  <v-app>
    <v-app-bar app color="white" v-if="shouldShow">
      <div class="d-flex align-center">
        <v-img
          alt="JTB Logo"
          class="mr-2"
          contain
          :src="require('@/assets/jtb-logo.png')"
          transition="scale-transition"
          width="50"
        />
      </div>
      <v-toolbar-title>{{$t('common.mainTitle')}}</v-toolbar-title>

      <v-spacer></v-spacer>
      <CodesDialog />
      <v-btn icon class="ml-3" text @click="logout">
        <v-icon >mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import CodesDialog from "@/codes/CodesDialog";
import firebase from "@/firebase/login";

export default {
  name: "App",
  components: {
    CodesDialog,
  },
  data: () => ({}),
  methods: {
    logout() {
      firebase.logout();
    },
  },
  computed: {
    shouldShow() {
      return !(this.$route.meta && (this.$route.meta.isAnonymus || this.$route.meta.isForLogin));
    },
  },
};
</script>
