<template>
  <v-container>
    <v-card v-if="place" elevation="1" class="white" height="100%">
      <v-card-text>
        <div class="d-flex flex-row align-center mr-9">
          <div>
            <v-btn icon large @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div>
            <v-card-title class="text-h3">
              {{ place.name_ja }}
            </v-card-title>
            <v-card-subtitle>{{ place.name }}</v-card-subtitle>
          </div>
          <v-btn @click="viewPlace" color="primary" icon><v-icon>mdi-pencil</v-icon></v-btn>
        </div>
        <h4>{{ $t("place.images") }}</h4>

        <v-slide-group show-arrows>
          <v-slide-item v-for="image in place.images" :key="image">
            <v-img
              max-height="150"
              max-width="250"
              class="ma-2"
              style="border-radius: 8px"
              :src="image"
            >
            </v-img>
          </v-slide-item>
        </v-slide-group>
      </v-card-text>
      <v-card-text>
        <h4>{{ $t("place.guides") }}:</h4>
        <v-expansion-panels class="mt-2">
          <v-expansion-panel v-for="(guide, index) in place.guides" :key="guide.title">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <div class="d-flex justify-space-between">
                  {{ guide.title_ja }} ({{ guide.title }})
                  <div class="d-flex" v-if="open">
                    <div class="mr-4">
                      <v-icon>mdi-timer-outline</v-icon>
                      {{ guide.duration | formatDuration }}
                    </div>
                    <div class="mr-4">
                      <v-icon>mdi-file</v-icon>
                      {{ guide.size | formatBytes }}
                    </div>
                  </div>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <h5 v-show="guide.images">{{$t('place.images')}}</h5>
                  <div class="overflow-x-auto" v-for="image in guide.images || []" :key="image">
                    <v-img
                      class="ma-2"
                      style="width: 100px; height: 100px; border-radius: 8px"
                      :src="image"
                    >
                    </v-img>
                  </div>
                  <h5 v-show="guide.manuscript_ja">{{$t('dialogs.guide.manuscript')}}</h5>
                  <div class="overflow-y-auto" style="max-height: 150px">
                    {{ guide.manuscript_ja }}
                  </div>
                </div>
                <v-btn @click="editGuide(index)" icon color="primary"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
          class="mt-4"
          @click="editGuide(place.guides ? place.guides.length : 0)"
          color="primary"
          outlined
          block
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t("place.addGuide") }}
        </v-btn>
      </v-card-text>
    </v-card>
    <EditPlaceDialog
      ref="editPlaceDialog"
      :country_id="countryDialog"
      :city_id="cityDialog"
      :place_id="placeDialog"
      @closed="closedDialog"
    />
    <EditGuideDialog
      ref="editGuideDialog"
      :country_id="countryDialog"
      :city_id="cityDialog"
      :place_id="placeDialog"
      :guide_index="guideDialog"
      @closed="closedDialog"
    />
  </v-container>
</template>

<script>
import EditPlaceDialog from "../dialogs/EditPlaceDialog.vue";
import EditGuideDialog from "../dialogs/EditGuideDialog.vue";

export default {
  name: "Place",
  components: {
    EditPlaceDialog,
    EditGuideDialog,
  },
  data() {
    return {
      countryDialog: null,
      cityDialog: null,
      placeDialog: null,
      guideDialog: null,
    };
  },
  methods: {
    closedDialog() {
      this.guideDialog = null;
    },
    viewPlace() {
      const { country, city, place } = this.$route.params;
      this.placeDialog = place;
      this.cityDialog = city;
      this.countryDialog = country;
      this.$refs.editPlaceDialog.dialog = true;
    },
    editGuide(guideIndex) {
      const { country, city, place } = this.$route.params;
      this.countryDialog = country;
      this.cityDialog = city;
      this.placeDialog = place;
      this.guideDialog = guideIndex;
      this.$refs.editGuideDialog.dialog = true;
    },
  },
  computed: {
    place() {
      const { country, city, place } = this.$route.params;
      if (!this.guides || !this.guides[country]) return null;
      return this.guides[country].cities[city].places[place];
    },
    guides() {
      return this.$store.state.jtbGuides;
    },
  },
};
</script>
