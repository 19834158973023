import { initializeApp } from "firebase/app";

const devConfig = {
  apiKey: "AIzaSyCsDEr-hjDrlT5hcgOjB-i12uLto9LGyH0",
  authDomain: "triploop-dev.firebaseapp.com",
  databaseURL: "https://triploop-dev.firebaseio.com",
  projectId: "triploop-dev",
  storageBucket: "triploop-dev.appspot.com",
  messagingSenderId: "68583121541",
  appId: "1:68583121541:web:aa454fdcb8851f2bc37fec",
  measurementId: "G-QE14GQE3YJ",
};

const prodConfig = {
  apiKey: "AIzaSyAg5L1YvRoH4UJ8p-o8a4FH7BGzvfWVYPA",
  authDomain: "triploop-12907.firebaseapp.com",
  databaseURL: "https://triploop-12907.firebaseio.com",
  projectId: "triploop-12907",
  storageBucket: "triploop-12907.appspot.com",
  messagingSenderId: "952406852926",
  appId: "1:952406852926:web:65b25521b26b7847",
  measurementId: "G-8YQFDYV8NT",
};

const firebaseApp = initializeApp(
  process.env.NODE_ENV === 'development' ?
    devConfig :
    prodConfig,
);

export default firebaseApp;
