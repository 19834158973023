import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

import Home from '../views/Home.vue';
import Login from '../views/Login';
import Place from '../views/Place';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isForLogin: true,
    },
  },
  {
    path: '/:country/:city/:place',
    name: 'place',
    component: Place,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // check to see if route requires auth
  if (to.matched.some((rec) => rec.meta.isAnonymus)) {
    console.log('is anonymus, going next');
    next();
  } else if (to.matched.some((rec) => rec.meta.isForLogin)) {
    console.log('isForLogin');
    const user = store.state.firUser;
    if (user) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
    // check auth state of user
    const user = store.state.firUser;
    if (user) {
      console.log('going to:', to);
      if (to.name === 'place' && !to.params.place) {
        next({ name: 'Home' });
        return;
      }
      next();
    } else {
      console.log(to);
      if (to.path !== "/") {
        console.log(`saving path ${to.path}`);
        router.startUrl = to.fullPath;
      }

      next({ name: 'Login', params: to.query });
    }
  }
});

export default router;
