<template>
  <v-dialog
    scrollable
    v-model="dialog"
    :width="codesArr.length > 0 ? '600px' : '400px'"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined v-bind="attrs" v-on="on">
        <span class="mr-2">{{ $t("codes.codes") }}</span>
        <v-icon>mdi-barcode</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h5">{{ $t("codes.codes") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <CreateCodeDialog :dark="true" :existingCode="editingCode" @closed="closedDialog" />
        </v-toolbar-items>

        <template v-if="codesArr.length > 0" v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item">
              {{ $t(`codes.${item}`) }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card v-if="codesArr.length == 0">
        <v-card-title> No Codes </v-card-title>
        <v-card-subtitle>Create your first code!</v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CreateCodeDialog />
        </v-card-actions>
      </v-card>
      <v-tabs-items v-else class="overflow-y-auto" style="max-height: 400px" v-model="tab">
        <v-tab-item v-for="(codesArr, indexA) in allCodes" :key="indexA">
          <v-card class="ma-2">
            <v-card-title v-if="codesArr.length == 0">No {{items[indexA]}} Codes</v-card-title>
            <v-list>
              <template v-for="(code, indexB) in codesArr">
                <v-list-item :key="indexB" :three-line="code.countries != null">
                  <v-list-item-content>
                    <v-list-item-title>{{ code.id }}</v-list-item-title>
                    <v-list-item-subtitle v-if="code.countries">{{
                      countryStr(code.countries)
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Valid until: {{ toDate(code.expires) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="editCode(code.id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon v-clipboard:copy="code.id">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="indexB < codesArr.length - 1" :key="`${indexB}_d`"></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateCodeDialog from "./CreateCodeDialog.vue";

export default {
  components: { CreateCodeDialog },
  data() {
    return {
      editingCode: null,
      dialog: false,
      tab: null,
      items: ["active", "expired"],
      now: new Date().getTime(),
    };
  },
  methods: {
    editCode(codeId) {
      this.editingCode = this.$store.state.jtbCodes[codeId];
    },
    toDate(date) {
      return new Date(date).toLocaleDateString("ja-JA");
    },
    countryStr(countries) {
      const guides = this.$store.state.jtbGuides;
      return countries.map((country) => guides[country].name_ja).join("、");
    },
    closedDialog() {
      this.editingCode = null;
    },
  },

  computed: {
    codesArr() {
      return Object.values(this.$store.state.jtbCodes);
    },
    allCodes() {
      const active = [];
      const expired = [];
      this.codesArr.forEach((code) => {
        if (code.expires > this.now) {
          active.unshift(code);
        } else {
          expired.unshift(code);
        }
      });
      return [active, expired];
    },
  },
};
</script>
