<template>
  <div
    class="ma-2"
    style="width: 100px; border-radius: 8px"
    :style="{ height: (height || defaultHeight) + 'px' }"
  >
    <v-card
      :style="`background-color:${isHovering ? '#f5f6fa' : 'white'}; height:100%`"
      justify="center"
      :id="`dropzone${audio ? '_audio' : '_image'}`"
      @click="$refs.image.click()"
      flat
    >
      <input
        type="file"
        style="display: none"
        ref="image"
        :accept="audio ? '.mp3' : 'image/*'"
        :multiple="!audio"
        @change="onFilePicked"
      />
      <v-container class="text-center">
        <div v-if="loading" class="mt-5">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-else>
          <v-icon>mdi-plus</v-icon>
          <h5 class="primary--text mt-2">
            {{ $t(`dragAndDrop.${audio ? "audioFile" : "file"}`) }}
          </h5>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import resizeImage from "@/plugins/image-resize.js";

export default {
  name: "DragAndDrop",
  props: ["audio", "height"],
  data() {
    return {
      uploadedFiles: [],
      isHovering: false,
      counter: 0,
      defaultHeight: 100,
      imageSrc: null,
      loading: false,
    };
  },
  computed: {
    cardColor() {
      return this.isHovering ? "accent" : "transparent";
    },
  },
  watch: {
    initial(newVal) {
      if (newVal) {
        this.loadInitialImage(newVal);
      }
    },
  },

  methods: {
    processFiles(files) {
      let count = files.length;
      this.loading = true;
      files.forEach(async (file) => {
        if (this.$props.audio) {
          this.$emit("update:file", file);
        } else {
          try {
            const resizedImageFile = await resizeImage({ file });
            this.$emit("update:file", resizedImageFile);
          } catch (e) {
            console.log(e);
          }
        }
        count -= 1;
        if (count === 0) {
          this.loading = false;
        }
      });
    },
    onFilePicked(e) {
      const { files } = e.target;
      if (!files) return;
      this.processFiles(files);
    },
  },
  mounted() {
    const { audio } = this.$props;
    const id = `#dropzone${audio ? "_audio" : "_image"}`;
    const self = this;
    document.querySelector(id).addEventListener("dragenter", () => {
      self.isHovering = true;
      self.counter += 1;
    });

    document.querySelector(id).addEventListener("dragleave", (e) => {
      e.preventDefault();

      self.counter -= 1;
      if (self.counter === 0) {
        self.isHovering = false;
      }
    });

    document.querySelector(id).addEventListener("dragover", (e) => {
      e.preventDefault();
    });

    document.querySelector(id).addEventListener("drop", async (e) => {
      e.preventDefault();

      const { files } = e.dataTransfer;
      this.processFiles(files);
    });
  },
};
</script>

<style>
#dropzone_audio {
  /* background-color: #f5f6fa; */
  border-style: dashed;
  border-width: 1px;
  border-color: #aaaaaa;
}
#dropzone_image {
  /* background-color: #f5f6fa; */
  border-style: dashed;
  border-width: 1px;
  border-color: #aaaaaa;
}
</style>
