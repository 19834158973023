import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import firebaseApp from "./init";

const auth = getAuth(firebaseApp);

const waitForLogin = ((callback) => {
  console.log('login.js->waitForLogin->waiting for user');
  onAuthStateChanged(auth, callback);
});

const emailSignIn = ((email, password) => {
  console.log('signing a user in');
  return signInWithEmailAndPassword(auth, email, password);
});

const logout = () => signOut(auth);

export default {
  emailSignIn,
  waitForLogin,
  logout,
};
