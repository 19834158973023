<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn @click="editCountry()" class="mx-2 small" color="primary">
        {{ $t("home.buttons.addCountry") }}
        <v-icon right>mdi-plus </v-icon>
      </v-btn>
    </div>
    <div v-for="(country, countryKey) in guides" :key="countryKey" class="my-9">
      <div class="d-flex mx-3">
        <h2>{{ country.name_ja }} ({{ country.name }})</h2>
        <v-btn @click="editCountry(countryKey)" class="mx-2 small" color="primary" text icon>
          <v-icon>mdi-pencil </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="editCity(countryKey)" class="mx-2 small" color="primary" outlined>
          {{ $t("home.buttons.addCity") }}<v-icon right>mdi-plus </v-icon>
        </v-btn>
      </div>
      <v-divider
        style="border-width: 3px !important; border-radius: 10px"
        class="primary ml-3 mt-3 mb-3"
      ></v-divider>
      <v-row class="pl-4">
        <v-col v-if="!country.cities">
          <v-card>
            <v-card-title> No Cities yet </v-card-title>
            <v-card-actions>
              <v-btn class="mb-2" @click="editCity(countryKey, cityKey)" color="primary">
                {{ $t("home.buttons.addCity") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-for="(city, cityKey) in country.cities" :key="cityKey">
          <v-card elevation="4" class="grey lighten-5">
            <v-card-title
              >{{ city.name_ja }} ({{ city.name }})
              <v-btn
                @click="editCity(countryKey, cityKey)"
                class="mx-2 small"
                color="primary"
                text
                icon
              >
                <v-icon>mdi-pencil </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="newPlace(countryKey, cityKey)"
                class="mx-2 small"
                color="primary"
                outlined
                >{{$t('home.buttons.addPlace')}}
                <v-icon>mdi-plus </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="6" lg="3" v-if="!city.places">
                  <v-card elevation="2">
                    <v-card-title> No Places yet </v-card-title>
                    <v-card-actions>
                      <v-btn class="mb-2" @click="newPlace(countryKey, cityKey)" color="primary">
                        Add a place
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="6"
                  lg="3"
                  v-for="(place, placeKey) in city.places"
                  :key="placeKey"
                >
                  <v-card
                    class="mx-auto"
                    height="100%"
                    :to="{
                      name: 'place',
                      params: {
                        country: countryKey,
                        city: cityKey,
                        place: placeKey,
                      },
                    }"
                  >
                    <!-- @click="viewPlace(countryKey, cityKey, placeKey)" -->
                    <v-img :src="place.images[0]" height="150px"></v-img>
                    <v-card-title>
                      <div class="text-truncate">
                        {{ place.name_ja }}
                      </div>
                    </v-card-title>
                    <v-card-subtitle>
                      <div class="text-truncate">{{ place.name }}</div>
                    </v-card-subtitle>
                    <v-card-text>
                      <span class="font-weight-medium"> {{$t('place.guides')}}</span>
                      <ol>
                        <li v-for="guide in place.guides" :key="guide.title">
                          {{ guide.title_ja }}
                        </li>
                      </ol>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- <ul>
                <li v-for="(place, placeKey) in city.places" :key="placeKey">
                  {{ place }}
                </li>
              </ul> -->
              <!-- {{city}} -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <EditCountryDialog ref="editCountryDialog" :country_id="countryDialog" @closed="closedDialog" />
    <EditCityDialog
      ref="editCityDialog"
      :country_id="countryDialog"
      :city_id="cityDialog"
      @closed="closedDialog"
    />
    <EditPlaceDialog ref="editPlaceDialog" :country_id="countryDialog" :city_id="cityDialog" />
  </v-container>
</template>

<script>
import EditCountryDialog from "../dialogs/EditCountryDialog.vue";
import EditCityDialog from "../dialogs/EditCityDialog.vue";
import EditPlaceDialog from "../dialogs/EditPlaceDialog.vue";

export default {
  name: "Home",

  data() {
    return {
      countryDialog: null,
      cityDialog: null,
    };
  },
  components: {
    EditCountryDialog,
    EditCityDialog,
    EditPlaceDialog,
  },
  methods: {
    closedDialog() {
      this.countryDialog = null;
      this.cityDialog = null;
    },
    newPlace(country, city) {
      this.countryDialog = country;
      this.cityDialog = city;
      this.$refs.editPlaceDialog.dialog = true;
    },
    editCity(country, city) {
      this.countryDialog = country;
      this.cityDialog = city;
      this.$refs.editCityDialog.dialog = true;
    },
    editCountry(country) {
      this.countryDialog = country;
      this.$refs.editCountryDialog.dialog = true;
    },
    viewPlace(countryKey, cityKey, placeKey) {
      console.log("clicked on", countryKey, cityKey, placeKey);
    },
  },
  computed: {
    guides() {
      return this.$store.state.jtbGuides;
    },
  },
};
</script>
