/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';

import database from '../firebase/database';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firUser: null,
    jtbGuides: {},
    jtbCodes: {},
  },
  mutations: {
    firUserUpdated: (state, user) => {
      state.firUser = user;
    },
    logOut: (state) => {
      state.firUser = null;
    },
    updateCollectionDocument: (state, payload) => {
      Vue.set(state[payload.collection], payload.document.id, payload.document);
    },
    deleteCollectionDocument: (state, payload) => {
      Vue.delete(state[payload.collection], payload.documentId);
    },
  },
  actions: {
    logOut: (context) => {
      database.stopObservers();
      context.commit('logOut');
    },
    userLogedIn: (context, user) => {
      database.startListening(context);
    },
  },
});
