<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" @click:outside="close">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          {{ $t(`home.dialogs.country.${country ? "edit" : "new"}`) }}
        </v-card-title>
        <v-card-title>{{ $t("home.dialogs.country.name") }}</v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :rules="nameRules"
                    :label="$t('common.japanese')"
                    v-model="countryJapName"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :rules="nameRules"
                    :label="$t('common.english')"
                    v-model="countryName"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> {{ $t("buttons.cancel") }} </v-btn>
          <v-btn color="primary" :loading="loading" @click="updateCountry">
            {{ $t("buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import database from "@/firebase/database";

export default {
  props: ["country_id"],
  data(self) {
    return {
      dialog: false,
      countryName: null,
      countryJapName: null,
      loading: false,
      nameRules: [
        (v) => !!v || self.$t("common.errors.noName"),
        (v) => (v && v.length >= 2) || self.$t("common.errors.nameLength", { chars: "2" }),
      ],
    };
  },
  methods: {
    async updateCountry() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      await database.updateCountry(this.$props.country_id, this.countryName, this.countryJapName);
      this.loading = false;
      this.close();
    },
    close() {
      this.dialog = false;
      this.countryName = "";
      this.countryJapName = "";
      this.$emit("closed");
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    country() {
      if (!this.guides || !this.$props.country_id) return null;
      return this.guides[this.$props.country_id];
    },
    guides() {
      return this.$store.state.jtbGuides;
    },
  },
  watch: {
    country(newVal, oldVal) {
      if (newVal && (!oldVal || newVal.name !== oldVal.name || newVal.name_ja !== oldVal.name_ja)) {
        this.countryName = newVal.name;
        this.countryJapName = newVal.name_ja;
      }
    },
  },
};
</script>
