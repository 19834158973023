const dataURItoBlob = (fileName, dataURI) => {
  const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
    ? atob(dataURI.split(',')[1])
    : unescape(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
  return new File([new Blob([ia], { type: mime })], fileName);
};

const resizeImage = ({ file, maxWidth = 960, maxHeight = 1280 }) => {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const resize = () => {
    let { width, height } = image;

    let imgRatio = width / height;
    const maxRatio = maxWidth / maxHeight;

    if (height > maxHeight || width > maxWidth) {
      if (imgRatio < maxRatio) {
        // adjust width according to maxHeight
        imgRatio = maxHeight / height;
        width *= imgRatio;
        height = maxHeight;
      } else if (imgRatio > maxRatio) {
        // adjust height according to maxWidth
        imgRatio = maxWidth / width;
        height *= imgRatio;
        width = maxWidth;
      } else {
        height = maxHeight;
        width = maxWidth;
      }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/jpeg', 80 / 100);

    return dataURItoBlob(file.name, dataUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'));
      return;
    }

    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
  });
};

export default resizeImage;
