<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700px" @click:outside="close">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          {{ $t(`home.dialogs.city.${city ? "edit" : "new"}`) }}
        </v-card-title>
        <v-card-title>{{ $t("home.dialogs.city.name") }}</v-card-title>
        <v-form ref="form" lazy-validation>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="nameRules"
                  :label="$t('common.japanese')"
                  v-model="cityJapName"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :rules="nameRules" :label="$t('common.english')" v-model="cityName">
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title>{{ $t("common.location.location") }}</v-card-title>
          <v-card-text>
            <div class="d-flex justify-center">
              <MglMap
                v-if="!manual || hasShownMap"
                v-show="!manual"
                style="width: 80%; height: 350px"
                :accessToken="accessToken"
                :mapStyle="mapStyle"
                @load="mapLoaded"
              >
                <MglMarker
                  v-if="marker.coordinates[0]"
                  anchor="bottom"
                  color="#9652ff"
                  :coordinates="marker.coordinates"
                ></MglMarker>
                <MglGeocoderControl
                  :accessToken="accessToken"
                  :mapboxgl="mapboxgl"
                  :placeholder="$t('home.dialogs.city.city')"
                  :marker="false"
                  @result="geocoded"
                />
              </MglMap>
            </div>
            <v-row v-show="manual">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="marker.coordinates[1]"
                  :label="$t('common.location.latitude')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="marker.coordinates[0]"
                  :label="$t('common.location.longitude')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn outlined color="primary" @click="manual = !manual">
              {{ $t(`common.location.${manual ? "manual" : "coordinates"}`) }}
            </v-btn>
            <p class="text-end mt-2 mb-0 error--text" v-if="locationError">{{ locationError }}</p>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>

        <v-card-actions>
          <!-- <v-btn color="error" text @click="deleteCity"> Delete </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> {{ $t("buttons.cancel") }} </v-btn>
          <v-btn color="primary" :loading="loading" @click="updateCity">
            {{ $t("buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "v-mapbox";
import MglGeocoderControl from "@geospoc/v-mapbox-geocoder";
import database from "@/firebase/database";

const initialState = (self) => ({
  dialog: false,
  cityName: null,
  cityJapName: null,
  loading: false,
  marker: { coordinates: [null, null] },
  manual: true,
  accessToken: process.env.VUE_APP_MAPBOX_ACCESS,
  mapStyle: process.env.VUE_APP_MAPSTYLE,
  hasShownMap: false,
  locationError: null,
  nameRules: [
    (v) => !!v || self.$t("common.errors.noName"),
    (v) => (v && v.length >= 2) || self.$t("common.errors.nameLength", { chars: "2" }),
  ],
});

export default {
  props: ["country_id", "city_id"],
  components: {
    MglMap,
    MglMarker,
    MglGeocoderControl,
  },
  data(self) {
    return initialState(self);
  },
  methods: {
    async updateCity() {
      if (!this.$refs.form.validate()) return;
      if (
        this.marker.coordinates[0] == null ||
        this.marker.coordinates[1] == null ||
        this.marker.coordinates[0] === "" ||
        this.marker.coordinates[1] === "" ||
        Number.isNaN(parseFloat(this.marker.coordinates[0])) ||
        Number.isNaN(parseFloat(this.marker.coordinates[1]))
      ) {
        this.error = this.$t("home.dialogs.city.errorNoLocation");
        return;
      }
      this.locationError = null;
      this.loading = true;
      await database.updateCity(
        this.$props.country_id,
        this.$props.city_id,
        this.cityName,
        this.cityJapName,
        {
          lat: parseFloat(this.marker.coordinates[1]),
          long: parseFloat(this.marker.coordinates[0]),
        },
      );
      this.loading = false;
      this.close();
    },
    close() {
      Object.assign(this.$data, initialState(this));
      this.$refs.form.resetValidation();
      this.$emit("closed");
    },
    mapLoaded(payload) {
      this.hasShownMap = true;
      if (this.marker.coordinates[0]) {
        payload.map.jumpTo({
          zoom: 10,
          center: this.marker.coordinates,
        });
      }
      payload.map.on("click", (e) => {
        const { lngLat } = e;

        this.marker = { coordinates: [lngLat.lng, lngLat.lat] };
      });
    },
    geocoded(result) {
      this.marker = {
        coordinates: result.result.center,
      };
    },
    setCity(city) {
      this.cityName = city.name;
      this.cityJapName = city.name_ja;
      this.marker = { coordinates: [city.coordinates.long, city.coordinates.lat] };
    },
  },
  computed: {
    mapboxgl() {
      return Mapbox;
    },
    city() {
      if (!this.country || !this.$props.city_id) return null;
      return this.country.cities[this.$props.city_id];
    },
    country() {
      if (!this.guides || !this.$props.country_id) return null;
      return this.guides[this.$props.country_id];
    },
    guides() {
      return this.$store.state.jtbGuides;
    },
  },
  created() {
    if (this.city) {
      this.setCity(this.city);
    }
  },
  watch: {
    city(newVal, oldVal) {
      if (
        newVal &&
        (!oldVal ||
          newVal.name !== oldVal.name ||
          newVal.name_ja !== oldVal.name_ja ||
          newVal.coordinates !== oldVal.coordinates)
      ) {
        this.setCity(newVal);
      } else {
        this.cityName = null;
        this.cityJapName = null;
        this.marker = { coordinates: [null, null] };
      }
    },
  },
};
</script>

<style >
.mgl-map-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}
.mgl-map-wrapper .mapboxgl-map {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
