<template>
  <div class="primary" style="height: 100%">
    <div class="d-none d-sm-flex">
      <v-img
        :src="require('@/assets/dotted_bck.png')"
        style="position: absolute; max-width: 80vw"
      ></v-img>
      <v-img
        :src="require('@/assets/shapes/01.png')"
        style="position: absolute; max-width: 30vw; right: 0; bottom: 0"
      ></v-img>
      <v-img
        :src="require('@/assets/shapes/02.png')"
        style="position: absolute; max-width: 10vw; right: 20%; bottom: 15%"
      ></v-img>
      <v-img
        :src="require('@/assets/shapes/03.png')"
        style="position: absolute; max-width: 30vw; top: 40%; left: 0"
      ></v-img>
      <v-img
        :src="require('@/assets/shapes/06.png')"
        style="position: absolute; max-width: 70vw; bottom: 0px; left: 0"
      ></v-img>
      <p
        class="white--text"
        style="position: absolute; bottom: 20px; left: 20px"
      >
        Powered by Trip Loop
      </p>
    </div>
    <div class="d-none d-sm-flex">
      <div
        style="
          margin-top: calc(20vh - 80px);
          margin-left: calc(50% - 372px);
          position: absolute;
          z-index: 1;
          width: 744px;
        "
      >
        <div class="circle d-flex justify-center">
          <img
            :src="require('@/assets/jtb-logo-square.png')"
            style="
              margin-top: 40px;
              max-width: 120px;
              max-height: 120px;
              height: auto;
              width: auto;
            "
          />
        </div>
      </div>
    </div>
    <div
      :style="
        $vuetify.breakpoint.xsOnly
          ? 'padding:80px 20px 0px 20px;'
          : 'padding-top: 20vh'
      "
    >
      <v-card
        max-width="544"
        class="mx-auto px-4 pb-4"
        :style="`${
          $vuetify.breakpoint.xsOnly
            ? 'padding-top: 20px'
            : 'margin-top: 40px; padding-top: 80px'
        }`"
      >
        <v-card-title class="text-h3 font-weight-black">Login</v-card-title>
        <v-card-text>
          <v-form lazy-validation ref="form">
            <v-text-field
              label="Email"
              v-model="email"
              append-icon="mdi-email"
              :rules="[rules.required, rules.email]"
            />
            <v-text-field
              label="Password"
              v-model="password"
              :rules="[rules.required, rules.min]"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-1"
              hint="At least 6 characters"
              counter
              @click:append="show = !show"
            />
            <p class="red--text" v-if="feedback">{{ feedback }}</p>
            <v-spacer></v-spacer>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            dark
            class="flex-grow-1"
            @click="emailSignIn()"
            :loading="loading"
            >Login</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import login from "@/firebase/login";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      show: false,
      rules: {
        required: (value) => !!value || "required",
        email: (text) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/.test(text) ||
          "Email is invalid",
        min: (v) => v.length >= 6 || "Min 6 characters",
      },
      loading: false,
      feedback: null,
    };
  },
  methods: {
    emailSignIn() {
      if (!this.$refs.form.validate()) {
        console.log("errors exist");
        return;
      }
      console.log(this.email, this.password);
      this.loading = true;
      this.feedback = null;
      login
        .emailSignIn(this.email, this.password)
        .then(() => {
          console.log("succes in signin in");
          this.loading = false;
        })
        .catch((error) => {
          console.log("loginError", error);
          this.loading = false;
          // Handle Errors here.
          const { code: errorCode, message: errorMessage } = error;

          switch (errorCode) {
            case "auth/email-already-in-use":
              this.feedback = this.$t("login.errors.email_in_use");
              break;
            case "auth/invalid-email":
              this.feedback = this.$t("login.errors.email_not_entered");
              break;

            case "auth/weak-password":
              this.feedback = this.$t("login.password_hint");
              break;
            case "auth/user-not-found":
            case "auth/wrong-password":
              this.feedback = this.$t("login.errors.wrong_username_password");
              break;
            default:
              this.feedback = error.message;
              break;
          }
          console.log("myError", errorCode, errorMessage);
        });
    },
  },
};
</script>

<style scoped>
.circle {
  width: 200px;
  height: 200px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
