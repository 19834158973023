import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import login from './firebase/login';
import i18n from './i18n';
import './plugins/clipboard';

Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.productionTip = process.env.NODE_ENV === 'production';

let app = null;
// wait for firebase auth to init before creating the app
let isSignedIn = false;
login.waitForLogin((user) => {
  console.log('waitForLogin');
  // init app if not already created
  store.commit('firUserUpdated', user);
  if (user) {
    isSignedIn = true;
    console.log('waitForLogin->Logged in user', user.uid);
    store.dispatch('userLogedIn', user);

    if (router.currentRoute.meta && router.currentRoute.meta.isForLogin) {
      if (router.startUrl) {
        console.log(`pushing to  ${router.startUrl}`);
        router.push({ path: router.startUrl });
      } else {
        router.push({ name: "Home" });
      }
    }
  } else if (isSignedIn) {
    console.log("signing out");
    isSignedIn = false;
    router.push({ name: "Login" });
    store.dispatch("logOut", store.state.firUser);
  }

  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount('#app');
  }
});

Vue.filter('formatBytes', (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const decimals = 2;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))}  ${sizes[i]}`;
});

Vue.filter('formatDuration', (value) => `${Math.trunc(value / 60)}:${(value % 60).toLocaleString('en-US', {
  minimumIntegerDigits: 2,
  useGrouping: false,
})}`);
