import {
  getDatabase,
  ref,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  update,
  child,
  set,
  off,
  remove,
  orderByValue,
  query,
} from "firebase/database";

import firebaseApp from "./init";

const database = getDatabase(firebaseApp);

const guidesRef = ref(database, 'audio-guides');
const codesRef = ref(database, 'jtbcodes');

const slugify = (text) => text
  .toString()
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .toLowerCase()
  .trim()
  .replace(/\s+/g, '-')
  .replace(/[^\w-]+/g, '')
  .replace(/--+/g, '-');

const listenAudioGidesNoPun = (context) => {
  const collection = 'jtbGuides';

  onChildAdded(query(guidesRef, orderByValue('created')), (snapshot) => {
    const document = { id: snapshot.key, ...snapshot.val() };
    context.commit('updateCollectionDocument', { collection, document });
  });

  onChildChanged(guidesRef, (snapshot) => {
    const document = { id: snapshot.key, ...snapshot.val() };
    context.commit('updateCollectionDocument', { collection, document });
  });
  onChildRemoved(guidesRef, (snapshot) => {
    context.commit('deleteCollectionDocument', { collection, documentId: snapshot.key });
  });
};

const stopObservers = () => {
  off(guidesRef);
  off(codesRef);
};

const listenCodes = (context) => {
  const collection = 'jtbCodes';
  onChildAdded(codesRef, (snapshot) => {
    const document = { id: snapshot.key, ...snapshot.val() };
    context.commit('updateCollectionDocument', { collection, document });
  });

  onChildChanged(codesRef, (snapshot) => {
    const document = { id: snapshot.key, ...snapshot.val() };
    context.commit('updateCollectionDocument', { collection, document });
  });
  onChildRemoved(codesRef, (snapshot) => {
    context.commit('deleteCollectionDocument', { collection, documentId: snapshot.key });
  });
};

const createCode = (code, data) => set(child(codesRef, code), data);
const deleteCode = (code, data) => remove(child(codesRef, code));

const startListening = (context) => {
  listenAudioGidesNoPun(context);
  listenCodes(context);
};

const updateCountry = ((countryId, countryName, countryJapName) => update(
  child(guidesRef, countryId ?? slugify(countryName)),
  {
    name: countryName,
    name_ja: countryJapName,
  },
));

const updateCity = ((countryId, cityId, cityName, cityJapName, coordinates) => update(
  child(guidesRef, `${countryId}/cities/${cityId ?? slugify(cityName)}`),
  {
    name: cityName,
    name_ja: cityJapName,
    coordinates,
  },
));
const updatePlace = ((
  countryId, cityId, placeId, placeName, placeJapName, images, coordinates,
) => update(
  child(guidesRef, `${countryId}/cities/${cityId}/places/${placeId ?? slugify(placeName)}`),
  {
    name: placeName,
    name_ja: placeJapName,
    images,
    coordinates,
  },
));

const updateGuide = ((
  countryId, cityId, placeId, index, guideName,
  guideJapName, manuscript, audioGuide, images, coordinates,
) => {
  const data = {
    title: guideName,
    title_ja: guideJapName,
    manuscript_ja: manuscript,
    images,
    url: audioGuide.url,
    duration: audioGuide.duration,
    size: audioGuide.size,
  };
  if (coordinates) {
    data.coordinates = coordinates;
  }
  update(
    child(guidesRef, `${countryId}/cities/${cityId}/places/${placeId}/guides/${index}`),
    data,
  );
});

const deletePlace = (countryId, cityId, placeId) => {
  remove(child(guidesRef, `${countryId}/cities/${cityId}/places/${placeId}`));
};

const deleteGuide = (countryId, cityId, placeId, guides, index) => {
  console.log(countryId, cityId, placeId, guides, index);
  guides.splice(index, 1);
  const guideRef = child(guidesRef, `${countryId}/cities/${cityId}/places/${placeId}/guides`);
  if (guides.length === 0) {
    remove(guideRef);
  } else {
    set(guideRef, guides);
  }
};

export default {
  startListening,
  updateCountry,
  updateCity,
  updatePlace,
  updateGuide,
  deleteGuide,
  deletePlace,
  createCode,
  deleteCode,
  stopObservers,
};
