// Load the required clients and packages
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const {
  S3Client,
  PutObjectCommand,
  ListObjectsCommand,
  DeleteObjectCommand,
  DeleteObjectsCommand,
} = require("@aws-sdk/client-s3");

// Set the AWS Region
const REGION = "eu-west-3"; // REGION

// Initialize the Amazon Cognito credentials provider
const s3 = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: 'eu-west-3:15e689c0-b1d9-4363-bb7b-d2e187c955f3', // IDENTITY_POOL_ID
  }),
});

const albumBucketName = "jtbguides"; // BUCKET_NAME

// Add an audioguide image
const addImage = async (file) => {
  const photoKey = `images/${file.name}`;
  const uploadParams = {
    Bucket: albumBucketName,
    Key: photoKey,
    Body: file,
  };
  try {
    await s3.send(new PutObjectCommand(uploadParams));
    return `images/${encodeURIComponent(file.name)}`;
  } catch (err) {
    throw new Error("There was an error uploading your photo: ", err.message);
  }
};
// Add an audioguide
const addGuide = async (file) => {
  const photoKey = `audios/${file.name}`;
  const uploadParams = {
    Bucket: albumBucketName,
    Key: photoKey,
    Body: file,
  };
  try {
    await s3.send(new PutObjectCommand(uploadParams));
    return `audios/${encodeURIComponent(file.name)}`;
  } catch (err) {
    throw new Error("There was an error uploading your photo: ", err.message);
  }
};

// // Make addPhoto function available to the browser
// window.addPhoto = addPhoto;

// // snippet-end:[s3.JavaScript.photoAlbumExample.addPhotoV3]
// // snippet-start:[s3.JavaScript.photoAlbumExample.deletePhotoV3]

// // Delete a photo from an album
const deleteImage = async (photoKey) => {
  try {
    const params = { Key: photoKey, Bucket: albumBucketName };
    await s3.send(new DeleteObjectCommand(params));
    return true;
  } catch (err) {
    throw Error("There was an error deleting your photo: ", err.message);
  }
};
const deleteAudio = async (audioKey) => {
  try {
    const params = { Key: audioKey, Bucket: albumBucketName };
    await s3.send(new DeleteObjectCommand(params));
    return true;
  } catch (err) {
    throw Error("There was an error deleting your audio: ", err.message);
  }
};
// // Make deletePhoto function available to the browser
// window.deletePhoto = deletePhoto;

// // snippet-end:[s3.JavaScript.photoAlbumExample.deletePhotoV3]
// // snippet-start:[s3.JavaScript.photoAlbumExample.deleteAlbumV3]

// // Delete an album from the bucket
// const deleteAlbum = async (albumName) => {
//   const albumKey = encodeURIComponent(albumName) + "/";
//   try {
//     const params = { Bucket: albumBucketName, Prefix: albumKey };
//     const data = await s3.send(new ListObjectsCommand(params));
//     const objects = data.Contents.map(function (object) {
//       return { Key: object.Key };
//     });
//     try {
//       const params = {
//         Bucket: albumBucketName,
//         Delete: { Objects: objects },
//         Quiet: true,
//       };
//       const data = await s3.send(new DeleteObjectsCommand(params));
//       listAlbums();
//       return alert("Successfully deleted album.");
//     } catch (err) {
//       return alert("There was an error deleting your album: ", err.message);
//     }
//   } catch (err) {
//     return alert("There was an error deleting your album1: ", err.message);
//   }
// };

export default {
  addImage,
  deleteImage,
  addGuide,
  deleteAudio,
};
