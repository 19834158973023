<template>
  <v-dialog v-model="dialog" width="500" @click:outside="closeDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :dark="dark" :text="dark" :color="dark ? '' : 'primary'" v-bind="attrs" v-on="on">
        <v-icon left>mdi-plus</v-icon> {{ $t("codes.new.create") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 primary white--text">
        <div class="d-flex justify-space-between" style="width: 100%">
          {{ $t("codes.create") }}
          <v-btn @click="closeDialog" icon color="white"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </v-card-title>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <v-text-field
            :rules="nameRules"
            :disabled="existingCode != null"
            prepend-icon="mdi-barcode"
            v-model="code"
            :label="$t('codes.new.code')"
          ></v-text-field>
          <div class="d-flex flex-row">
            <v-checkbox v-model="allCountries" :label="$t('codes.new.allCountries')"></v-checkbox>
            <v-select
              class="ml-3"
              item-text="name"
              item-value="key"
              multiple
              v-if="!allCountries"
              v-model="selectedCountries"
              :items="countries"
              label="Countries"
              :error-messages="countryError ? $t('codes.new.countriesError') : null"
            ></v-select>
          </div>
          <v-menu
            v-model="pickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="expDate"
                :label="$t('codes.new.expires')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              :min="today"
              v-model="expDate"
              @input="pickerMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
      </v-form>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-dialog v-model="deleteDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-show="existingCode != null" text v-bind="attrs" v-on="on">
              {{ $t("buttons.delete") }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h6 primary white--text">
              {{ $t("codes.delete.title") }}
            </v-card-title>

            <v-card-text class="mt-2">
              {{ $t("codes.delete.content") }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleteDialog = false">
                {{ $t("buttons.cancel") }}
              </v-btn>
              <v-btn color="primary" @click="deleteCode"> {{ $t("buttons.delete") }} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn color="primary" @click="createCode">
          {{ $t(`codes.new.${existingCode != null ? "update" : "create"}`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import database from "@/firebase/database";

export default {
  props: ["dark", "existingCode"],
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      code: null,
      pickerMenu: false,
      today: null,
      expDate: null,
      countryError: false,
      valid: false,
      allCountries: true,
      selectedCountries: [],
      nameRules: [
        (v) => !!v || "Code is required",
        (v) => (v && v.length >= 3) || "Code must be at least 3 characters",
        (v) => (v && !v.includes(".")) || "Don't include '.' dots in the code",
      ],
    };
  },
  methods: {
    async createCode() {
      if (!this.$refs.form.validate()) return;

      if (!this.allCountries && this.selectedCountries.length === 0) {
        this.countryError = true;
        return;
      }
      this.countryError = false;
      const data = {
        created: new Date().getTime(),
        expires: new Date(this.expDate).getTime(),
      };
      if (!this.allCountries) {
        data.countries = this.selectedCountries;
      }

      await database.createCode(this.code, data);
      this.closeDialog();
    },
    async deleteCode() {
      await database.deleteCode(this.code);
      this.deleteDialog = false;
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
      this.code = null;
      this.countryError = false;
      this.allCountries = true;
      this.selectedCountries = [];
      this.$refs.form.resetValidation();
      this.$emit("closed");
    },
    numberFormatted(num) {
      return num.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    },
    formatDate(date) {
      const month = this.numberFormatted(date.getMonth() + 1);
      const day = this.numberFormatted(date.getDate());
      return `${date.getFullYear()}-${month}-${day}`;
    },
  },
  computed: {
    countries() {
      const guides = this.$store.state.jtbGuides;
      return Object.keys(guides).map((key) => ({ key, name: guides[key].name_ja }));
    },
  },
  created() {
    const today = new Date();
    this.today = this.formatDate(today);
    this.expDate = this.formatDate(new Date(today.setMonth(today.getMonth() + 2)));
  },
  watch: {
    existingCode(newCode) {
      if (newCode) {
        this.dialog = true;
        this.code = newCode.id;
        this.expDate = this.formatDate(new Date(newCode.expires));
        if (newCode.countries) {
          this.selectedCountries = newCode.countries;
          this.allCountries = false;
        }
      }
    },
  },
};
</script>
