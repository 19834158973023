import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    // options: {
    //   customProperties: true,
    // },
    themes: {
      light: {
        primary: '#CC0100',
        // primary: '#5D74E2',
        // success: '#3cd1c2',
        // info: '#ffaa2c',
        // error: '#f83e70',
      },
    },
  },
});
